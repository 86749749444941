import React, { useMemo, useState } from 'react';

import { Divider, IconButton, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { generatePath, useHistory } from 'react-router-dom';

import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import AssetContactDialog from 'components/_dialogs/AssetContactDialog/AssetContactDialog';
import UploadFileDialog from 'components/_dialogs/UploadFileDialog/UploadFileDialog';
import AssetPrintTemplate from 'components/_print/_templates/AssetPrintTemplate/AssetPrintTemplate';
import DetailsCardBaseSection from 'components/DetailsCardBaseSection/DetailsCardBaseSection';
import DetailsCardHeader from 'components/DetailsCardHeader/DetailsCardHeader';
import Loader from 'components/Loader';
import assetContactsApi from 'config/api/assetContacts/assetContacts';
import assetDocumentsApi from 'config/api/assetDocuments/assetDocuments';
import { AssetDocument } from 'config/api/assetDocuments/assetDocuments.types';
import assetInformationChoicesApi from 'config/api/assetInformationChoices/assetInformationChoices';
import type { AssetDetails } from 'config/api/assets/assets.types';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import DATE_FORMAT from 'config/constants/DATE_FORMAT';
import ensureIsArray from 'helpers/ensureIsArray';
import safeT from 'helpers/safeT/safeT';
import stringShortener from 'helpers/stringShortener';
import useBoolState from 'hooks/useBoolState';
import assets_messages from 'messages/assets_messages';
import general_messages from 'messages/general_messages';
import { useConfirmationModalContext } from 'reactContext/ConfirmationModalContext';
import PATHS from 'router/PATHS';
import { Id } from 'types/Id';
import type { Option } from 'types/Option';

import { FileInForm } from '../../types/FileForm';
import ColoredButton from '../ColoredButton';
import UserAvatarWithDescription from '../UserAvatarWithDescription/UserAvatarWithDescription';

import useStyles from './AssetDetailsCard.styles';

type Props = {
  assetData: AssetDetails;
};

const findInOptions = (valueToFind: string, array: Option[]): string[] =>
  ensureIsArray(array.find(({ key }) => key === valueToFind)?.label || '');

const AssetDetailsCard: React.FC<Props> = ({ assetData }) => {
  const { id, name, category, owner, info, key } = assetData;
  const { t, i18n } = useTranslation();

  // @ts-ignore
  const { showConfirmationModal } = useConfirmationModalContext();
  const queryClient = useQueryClient();

  const { state: isUploadDialogOpen, setTrue: openUploadDialog, setFalse: closeUploadDialog } = useBoolState(false);
  const [contactDialog, setContactDialog] = useState<{ isOpen: boolean; contactId: Id | null }>({
    contactId: null,
    isOpen: false,
  });
  const closeContactDialog = () => setContactDialog({ contactId: null, isOpen: false });
  const openContactDialog = (contactId: Id) => () => setContactDialog({ contactId, isOpen: true });
  const onRemoveContact = (contactId: Id) => async () => {
    const confirmed = await showConfirmationModal({
      title: t(general_messages.are_you_sure),
      body: t(general_messages.operation_irreversible),
    });
    if (confirmed) {
      await assetContactsApi.removeAssetContact(contactId);
      await queryClient.invalidateQueries([QUERY_KEYS.ASSETS_GET_SINGLE, assetData.id.toString()]);
    }
  };

  const history = useHistory();

  const { data: assetInformationChoices, isLoading: choicesAreLoading } = useQuery(
    QUERY_KEYS.ASSETS_INFORMATION_CHOICES,
    assetInformationChoicesApi.getAssetInformationChoices,
  );

  const onEdit = () => {
    history.push(generatePath(`${PATHS.ASSETS_EDIT}/:assetId`, { assetId: id }));
  };

  const onClose = () => {
    history.goBack();
  };

  const onDocumentRemove = async (documentData: AssetDocument) => {
    const confirmed = await showConfirmationModal({
      title: t(general_messages.are_you_sure),
      body: t(general_messages.operation_irreversible),
    });

    if (confirmed) {
      await assetDocumentsApi.removeAssetDocument(documentData.id);
      await queryClient.invalidateQueries([QUERY_KEYS.ASSETS_GET_SINGLE, assetData.id.toString()]);
    }
  };

  const { information_label: labelMessages } = assets_messages;
  const sectionTitles = useMemo(
    () => ({
      section1: t(labelMessages.section_title_1),
      section2: t(labelMessages.section_title_2),
      section3: t(labelMessages.section_title_3),
      section4: t(labelMessages.section_title_4),
      legal_title: t(labelMessages.legal_title),
      gdpr_title: t(labelMessages.gdpr_title),
      files_title: t(labelMessages.files_title),
      files_description: t(labelMessages.files_description),
      contacts_title: t(labelMessages.contacts_title),
    }),
    [i18n.language],
  );
  const styles = useStyles();

  const content = useMemo(() => {
    if (!assetInformationChoices) return null;

    return {
      section1: {
        title: sectionTitles.section1,
        elements: [
          { name: t(labelMessages.friendly_id_label), value: key, key: 'key' },
          { name: t(labelMessages.name), value: name, key: 'name' },
          { name: t(labelMessages.category), value: safeT(t, category.nameTranslationKey, category.name), key: 'category' },
        ],
      },
      section2: {
        title: sectionTitles.section2,
        elements: [
          { name: t(labelMessages.internal_asset_identity), value: info.internalAssetIdentity, key: 'internalAssetIdentity' },
          { name: t(labelMessages.asset_sub_category), value: info.assetSubcategory, key: 'assetSubcategory' },
          { name: t(labelMessages.asset_description), value: info.assetDescription, key: 'assetDescription' },
        ],
      },
      section3: {
        title: sectionTitles.section3,
        elements: [
          {
            name: t(labelMessages.confidentiality),
            value: safeT(t, ...findInOptions(info.confidentiality, assetInformationChoices?.confidentiality as Option[])),
            key: 'confidentiality',
          },
          {
            name: t(labelMessages.integrity_change_control),
            value: safeT(t, ...findInOptions(info.integrityChangeControl, assetInformationChoices?.integrityChangeControl as Option[])),
            key: 'integrityChangeControl',
          },
          {
            name: t(labelMessages.availability),
            value: safeT(t, ...findInOptions(info.availability, assetInformationChoices?.availability as Option[])),
            key: 'integrityChangeControl',
          },
          {
            name: t(labelMessages.assets_importance),
            value: safeT(t, ...findInOptions(info.assetsImportance, assetInformationChoices?.assetsImportance as Option[])),
            key: 'assetsImportance',
          },
        ],
      },
      section4: {
        title: sectionTitles.section4,
        elements: [
          { name: t(labelMessages.placement_of_asset), value: info.placementOfAsset, key: 'placementOfAsset' },
          { name: t(labelMessages.current_status_of_asset), value: info.currentStatusOfAsset, key: 'currentStatusOfAsset' },
          { name: t(labelMessages.economical_value_of_asset), value: info.economicalValueOfAsset, key: 'economicalValueOfAsset' },
        ],
      },
      legal: {
        title: sectionTitles.legal_title,
        elements: [
          {
            name: t(labelMessages.legal_demands_checkbox),
            value: info.legalDemands || t(general_messages.not_applicable),
            key: 'legalDemands',
            wideLabel: true,
          },
        ],
      },
      gdpr: {
        title: sectionTitles.gdpr_title,
        elements: [
          {
            name: t(labelMessages.identifiable_information_checkbox),
            value: info.identifiableInformation || t(general_messages.not_applicable),
            key: 'identifiableInformation',
            wideLabel: true,
          },
          {
            name: t(labelMessages.sensitive_individual_checkbox),
            value: info.sensitiveIndividualInformation || t(general_messages.not_applicable),
            key: 'sensitiveIndividualInformation',
            wideLabel: true,
          },
        ],
      },
      contacts: {
        title: sectionTitles.contacts_title,
      },
      files: {
        title: sectionTitles.files_title,
        subtitle: sectionTitles.files_description,
      },
    };
  }, [i18n.language, assetInformationChoices, info]);

  const onFileDialogSubmit = async (formData: FileInForm) => {
    await assetDocumentsApi.addAssetDocument({ file: formData.file, label: formData.name, asset: assetData.id });
    await queryClient.invalidateQueries([QUERY_KEYS.ASSETS_GET_SINGLE, assetData.id.toString()]);
  };

  if (choicesAreLoading || !content) return <Loader inner />;

  return (
    <>
      <DetailsCardHeader
        onClose={onClose}
        onEdit={onEdit}
        printTemplate={
          <AssetPrintTemplate
            sections={[
              { title: content.section1.title, elements: content.section1.elements },
              { title: content.section2.title, elements: content.section2.elements },
              { title: content.section3.title, elements: content.section3.elements },
              { title: content.section4.title, elements: content.section4.elements },
              { title: content.legal.title, elements: content.legal.elements },
              { title: content.gdpr.title, elements: content.gdpr.elements },
              {
                title: t(labelMessages.owner),
                elements: [
                  { name: t(general_messages.full_name), value: owner.fullName },
                  { name: t(general_messages.email), value: owner.email },
                ],
              },
              {
                title: content.contacts.title,
                customElement: (
                  <table>
                    <thead>
                      <tr>
                        <th>{t(assets_messages.contact.name_label)}</th>
                        <th>{t(general_messages.phone)}</th>
                        <th>{t(general_messages.email)}</th>
                        <th>{t(assets_messages.contact.comment_label)}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {assetData?.contacts.map(
                        ({ id: contactId, email, category: contactCategory, name: contactName, comment, phoneNumber }) => (
                          <tr key={contactId}>
                            <td>{`${contactName} (${contactCategory})`}</td>
                            <td>{phoneNumber}</td>
                            <td>{email}</td>
                            <td>{comment}</td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </table>
                ),
              },
              {
                title: t(content.files.title),
                customElement: (
                  <table>
                    <thead>
                      <tr>
                        <th>{t(assets_messages.information_label.files_label)}</th>
                        <th>{t(assets_messages.information_label.filename)}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {assetData.documents
                        ? assetData.documents.map(({ label, attachment, id: documentId }) => (
                            <tr key={documentId}>
                              <td>{label}</td>
                              <td>{attachment.name}</td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                ),
              },
            ]}
            title={`${assetData.name} - ${safeT(t, assetData.category.nameTranslationKey, assetData.category.name)}`}
          />
        }
        subtitle={safeT(t, category.nameTranslationKey, category.name)}
        title={name}
      />
      <div className={styles.body}>
        <DetailsCardBaseSection elements={content.section1.elements} title={content.section1.title} />
        <DetailsCardBaseSection elements={content.section2.elements} title={content.section2.title} />
        <DetailsCardBaseSection elements={content.section3.elements} title={content.section3.title} />
        <DetailsCardBaseSection elements={content.section4.elements} title={content.section4.title} />
        <DetailsCardBaseSection elements={content.legal.elements} title={content.legal.title} />
        <DetailsCardBaseSection elements={content.gdpr.elements} title={content.gdpr.title} />
        <DetailsCardBaseSection showDivider title={t(labelMessages.owner)}>
          <UserAvatarWithDescription
            description={[owner.fullName, owner.email]}
            userAvatarProps={{ size: 'm', userData: owner, userId: owner.id }}
          />
        </DetailsCardBaseSection>
        <DetailsCardBaseSection showDivider title={content.contacts.title}>
          <div className={styles.contacts}>
            {!!assetData?.contacts.length &&
              assetData.contacts.map(({ id: contactId, email, category: contactCategory, name: contactName, comment, phoneNumber }) => (
                <div>
                  <div key={contactId} className={styles.contactRow}>
                    <div className={styles.contactCell}>
                      <Typography>
                        {contactName} ({contactCategory})
                      </Typography>
                      {comment && <Typography variant='caption'>{comment}</Typography>}
                    </div>
                    <div className={styles.contactCell}>
                      <Typography align='right'>
                        {`${t(general_messages.phone)}: `}
                        <Typography color='primary' component='a' href={`tel:${phoneNumber}`}>
                          {phoneNumber}
                        </Typography>
                      </Typography>
                      <Typography align='right'>
                        {`${t(general_messages.email)}: `}
                        <Typography color='primary' component='a' href={`mailto:${email}`}>
                          {email}
                        </Typography>
                      </Typography>
                    </div>
                    <div className={styles.contactButtons}>
                      <IconButton onClick={openContactDialog(contactId)} size='small'>
                        <PenIcon />
                      </IconButton>
                      <IconButton onClick={onRemoveContact(contactId)} size='small'>
                        <TrashIcon />
                      </IconButton>
                    </div>
                  </div>
                  <Divider />
                </div>
              ))}
            <div className={styles.buttonWrapper}>
              {/* @ts-ignore */}
              <ColoredButton customColor='secondary' onClick={openContactDialog(null)}>
                {t(assets_messages.details_page.add_contact_button)}
              </ColoredButton>
            </div>
          </div>
        </DetailsCardBaseSection>
        <DetailsCardBaseSection showDivider subtitle={content.files.subtitle} title={content.files.title}>
          {!!assetData?.documents.length &&
            assetData.documents.map(document => {
              const { id: documentId, label, createdAt, attachment, updatedAt } = document;
              return (
                <div key={documentId}>
                  <div className={styles.documentRow}>
                    <Typography>{label}</Typography>
                    <Typography color='primary' component='a' href={attachment.url}>
                      {stringShortener(attachment.name, 30, true)}
                    </Typography>
                    <IconButton onClick={() => onDocumentRemove(document)} size='small'>
                      <TrashIcon />
                    </IconButton>
                    <Typography className={styles.details} variant='caption'>
                      {`${t(general_messages.created_at)}: ${format(createdAt, DATE_FORMAT.defaultDate)}`}
                      {', '}
                      {`${t(general_messages.updated_at)}: ${format(updatedAt, DATE_FORMAT.defaultDate)}`}
                    </Typography>
                  </div>
                  <Divider />
                </div>
              );
            })}
        </DetailsCardBaseSection>
        <div className={styles.buttonWrapper}>
          {/* @ts-ignore */}
          <ColoredButton customColor='secondary' onClick={openUploadDialog}>
            {t(assets_messages.details_page.upload_file_button)}
          </ColoredButton>
        </div>
        {isUploadDialogOpen && (
          <UploadFileDialog onClose={closeUploadDialog} onSubmit={onFileDialogSubmit} open={isUploadDialogOpen} withLabelField />
        )}
        {contactDialog.isOpen && (
          <AssetContactDialog
            assetId={assetData.id}
            contactId={contactDialog.contactId}
            onClose={closeContactDialog}
            open={contactDialog.isOpen}
          />
        )}
      </div>
    </>
  );
};

export default AssetDetailsCard;
