import React, { useCallback, useMemo, useState } from 'react';

import { IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { generatePath, useHistory } from 'react-router-dom';

import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import ContextMenu from 'components/ContextMenu/ContextMenu';
import FooterButton from 'components/FooterButton';
import Table from 'components/Table';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import assets, { assetsApi } from 'config/api/assets/assets';
import { parseAllAssetForFE } from 'config/api/assets/assets.parsers';
import { Asset } from 'config/api/assets/assets.types';
import ROLES from 'config/constants/ROLES';
import EVENTS from 'config/events/pubsub';
import safeT from 'helpers/safeT/safeT';
import useFeatureFlagCheck from 'hooks/useFeatureFlagCheck/useFeatureFlagCheck';
import useIsOwner from 'hooks/useIsOwner/useIsOwner';
import usePagination from 'hooks/usePagination';
import usePermissions from 'hooks/usePermissions';
import usePrepareAvailableFilters from 'hooks/usePrepareAvailableFilters/usePrepareAvailableFilters';
import useSubscription from 'hooks/useSubscription';
import assets_messages from 'messages/assets_messages';
import general_messages from 'messages/general_messages';
import { useConfirmationModalContext } from 'reactContext/ConfirmationModalContext';
import PATHS from 'router/PATHS';
import assetsCategoryFilter from 'services/_filters/parsers/_assetsFilters/assetsCategoryFilter';
import assetsImportanceFilter from 'services/_filters/parsers/_assetsFilters/assetsImportanceFilter';
import availabilityFilter from 'services/_filters/parsers/_assetsFilters/availabilityFilter';
import confidentialityFilter from 'services/_filters/parsers/_assetsFilters/confidentialityFilter';
import identifiableInformationFilter from 'services/_filters/parsers/_assetsFilters/identifiableInformationFilter';
import integrityChangeControlFilter from 'services/_filters/parsers/_assetsFilters/integrityChangeControlFilter';
import legalDemandsFilter from 'services/_filters/parsers/_assetsFilters/legalDemandsFilter';
import myAssetsFilter from 'services/_filters/parsers/_assetsFilters/myAssetsFilter';
import sensitiveIndividualInformationFilter from 'services/_filters/parsers/_assetsFilters/sensitiveIndividualInformationFilter';
import parseFiltersForRequest from 'services/parseFiltersForRequest';
import { Id } from 'types/Id';
import { TableDataMappingRow } from 'types/Table';

const AssetsTable: React.FC = () => {
  const [isAssetsManager] = usePermissions([ROLES.ORGANIZATION.ASSETS_MANAGER]);
  const { availableFeatures } = useFeatureFlagCheck();
  const { t } = useTranslation();
  const [sort, setSort] = useState<{ key: string | null; direction: string | null }>({ key: null, direction: null });
  const [filters, setFilters] = useState({});
  const onSort = (key: string, direction: string) => setSort({ key, direction });
  // @ts-ignore
  const { showConfirmationModal } = useConfirmationModalContext();
  const { checkIsOwner } = useIsOwner();
  const history = useHistory();

  const availableFilters = usePrepareAvailableFilters({
    category_id: assetsCategoryFilter,
    only_my_assets: myAssetsFilter,
    confidentiality: confidentialityFilter,
    integrity_change_control: integrityChangeControlFilter,
    availability: availabilityFilter,
    identifiable_information: identifiableInformationFilter,
    sensitive_individual_information: sensitiveIndividualInformationFilter,
    assets_importance: assetsImportanceFilter,
    legal_demands: legalDemandsFilter,
  });

  const {
    data: tableData,
    getData: getTableData,
    hasNextPage,
    loading,
    refreshData,
  } = usePagination({
    endpointFunc: assets.getAllAssets,
    limits: [30, 20, 10],
    dependencies: [sort, filters],
    filters: parseFiltersForRequest(filters, {}, availableFilters || {}),
    sort,
  });

  const deleteMutation = useMutation(['delete asset'], assetsApi.removeAsset, {
    onSuccess: refreshData,
  });

  const onEdit = async (id: Id) => {
    history.push(generatePath(`${PATHS.ASSETS_EDIT}/:assetId`, { assetId: id }));
  };

  const getRowHref = ({ id, owner }: Asset) => {
    const canView = checkIsOwner(owner.id) || isAssetsManager;
    return canView ? generatePath(`${PATHS.ASSETS}/:assetId`, { assetId: id }) : null;
  };

  const onDelete = async ({ name, id }: Asset) => {
    const shouldProceed = await showConfirmationModal({
      title: t(assets_messages.remove_asset_modal.title),
      body: t(assets_messages.remove_asset_modal.body, { assetName: name }),
    });
    if (shouldProceed) {
      deleteMutation.mutate(id);
    }
  };

  useSubscription(EVENTS.ASSETS_UPDATED, refreshData);

  const renderContextMenu = useCallback(
    (data: Asset) => {
      const elements = [
        ...(isAssetsManager || checkIsOwner(data.owner.id) ? [{ label: t(general_messages.edit), onClick: () => onEdit(data.id) }] : []),
        ...(isAssetsManager ? [{ label: t(general_messages.delete), onClick: () => onDelete(data) }] : []),
      ];

      if (!elements.length) return null;
      return (
        <ContextMenu elements={elements} id={`assets-table-row-${data.id}`}>
          <IconButton>
            <MoreIcon />
          </IconButton>
        </ContextMenu>
      );
    },
    [tableData, isAssetsManager],
  );

  const dataMapping = useMemo(() => {
    const base: TableDataMappingRow<Asset>[] = [
      {
        id: 'name',
        width: '20%',
        label: t(assets_messages.assets_table.name),
        get: ({ name }) => name,
      },
      {
        id: 'owner',
        width: '20%',
        label: t(assets_messages.assets_table.owner),
        get: ({ owner }) => (
          <UserAvatar
            showName
            userData={{ firstName: owner.firstName, lastName: owner.lastName, imageUrl: owner.imageUrl, email: owner.email }}
            userId={owner.id}
          />
        ),
      },
      {
        id: 'category',
        width: '20%',
        label: t(assets_messages.assets_table.category),
        get: ({ category }) => safeT(t, category.nameTranslationKey, category.name),
      },
      {
        id: 'description',
        width: '34%',
        label: t(assets_messages.assets_table.description),
        get: ({ info }) => info.assetDescription,
        blockSorting: true,
      },
    ];

    base.push({
      id: 'context',
      width: '6%',
      label: '',
      isAddon: true,
      get: (data): React.ReactNode => renderContextMenu(data) || ' ',
      blockSorting: true,
    });
    return base;
  }, [t, tableData, isAssetsManager]);

  return (
    <>
      <Table
        clickableRow
        data={tableData && parseAllAssetForFE(tableData)}
        dataMapping={dataMapping}
        // @ts-ignore
        filtersService={availableFilters && availableFeatures.canFilterAssets ? { availableFilters, filters, setFilters } : undefined}
        getRowHref={getRowHref}
        onSort={onSort}
        refetching={loading}
        sort={sort}
      />
      {hasNextPage && (
        // @ts-ignore
        <FooterButton loading={loading} onClick={getTableData}>
          {t(general_messages.show_more)}
        </FooterButton>
      )}
    </>
  );
};

export default AssetsTable;
