export const POCKETSAFE_EDITOR_BASE = 'pocketsafe-manager';
export const AUTH_BASE = 'auth';
export const ORGANIZATION_BASE = 'my-organization';
export const DASHBOARD_BASE = 'dashboard';

export const AUTH_PATHS = {
  LOGIN: 'login',
  REGISTER: 'register',
  FORGOT_PASSWORD: 'forgot-password',
  PASSWORD_RESET_LINK_SENT: 'password-reset-sent',
  PASSWORD_RESET: 'reset-password',
  PASSWORD_CHANGED: 'password-changed',
  ACCOUNT_ACTIVATE: 'account-activate',
  REGISTRATION_LINK_SENT: 'registration-link-send',
};

const PATHS = {
  ROOT: '/',
  AUTH: `/${AUTH_BASE}`,
  AUTH_LOGIN: `/${AUTH_BASE}/${AUTH_PATHS.LOGIN}`,
  AUTH_REGISTER: `/${AUTH_BASE}/${AUTH_PATHS.REGISTER}`,
  AUTH_FORGOT_PASSWORD: `/${AUTH_BASE}/${AUTH_PATHS.FORGOT_PASSWORD}`,
  AUTH_PASSWORD_RESET_LINK_SENT: `/${AUTH_BASE}/${AUTH_PATHS.PASSWORD_RESET_LINK_SENT}`,
  AUTH_PASSWORD_RESET: `/${AUTH_BASE}/${AUTH_PATHS.PASSWORD_RESET}`,
  AUTH_PASSWORD_CHANGED: `/${AUTH_BASE}/${AUTH_PATHS.PASSWORD_CHANGED}`,
  AUTH_ACCOUNT_ACTIVATE: `/${AUTH_BASE}/${AUTH_PATHS.ACCOUNT_ACTIVATE}`,
  AUTH_REGISTRATION_LINK_SENT: `/${AUTH_BASE}/${AUTH_PATHS.REGISTRATION_LINK_SENT}`,
  MANAGE_CONTENT: `/${POCKETSAFE_EDITOR_BASE}/manage-content`,
  EDIT_ARTICLE: `/${POCKETSAFE_EDITOR_BASE}/article`,
  EDIT_GUIDE: `/${POCKETSAFE_EDITOR_BASE}/guide`,
  MANAGE_MEETING_SLOTS: `/${POCKETSAFE_EDITOR_BASE}/manage-meeting-slots`,
  ADVISORY_PAGE: `/${POCKETSAFE_EDITOR_BASE}/advisory`,
  ARTICLES: '/articles',
  MY_PROFILE: '/my-profile',
  GUIDES: '/guides',
  SELF_TESTS: '/self-tests',
  ASSESSMENTS: '/assessments',
  USERS: '/users',
  CONTENT_BY_SERVICE_AREA: '/service-area',
  BOOK_MEETING: '/book-meeting',
  INCIDENT_REPORTS: '/incident-reports',
  INCIDENT_REPORT: '/incident-report',
  MEETING_ADVICES: '/meeting-advices',
  ORGANIZATION_ADMINISTRATION: '/organization-administration',
  MEETING_REPORT: '/meeting-report',
  GLOBAL_SEARCH: '/global-search',
  QUESTIONS: '/questions',
  WORKPLACES: `/${ORGANIZATION_BASE}/workplaces`,
  WORKPLACES_EDIT: `/${ORGANIZATION_BASE}/workplaces/edit`,
  WORKPLACES_TEMPLATE: `/${ORGANIZATION_BASE}/workplaces/template`,
  ASSETS: `/${ORGANIZATION_BASE}/assets`,
  ASSETS_EDIT: `/${ORGANIZATION_BASE}/assets/edit`,
  YEAR_PLANNER_ASSETS_ACTIVITY: `/${ORGANIZATION_BASE}/year_planner_activity`,
  YEAR_PLANNER_ASSETS_ACTIVITY_EDIT: `/${ORGANIZATION_BASE}/year_planner_activity/edit`,
  PRE_PAYMENT: '/payment',
  ASSET_TASKS_DASHBOARD: `/${DASHBOARD_BASE}/asset-tasks`,
} as const;

export type PathKeys = keyof typeof PATHS;
export type PathValues = typeof PATHS[PathKeys];

export default PATHS;
