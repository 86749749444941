import React, { useEffect, useState } from 'react';

import { Button, makeStyles, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import DialogGrid from 'components/_dialogs/_components/DialogGrid';
import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import FormSwitch from 'components/FormSwitch';
import FormTextInput from 'components/FormTextInput';
import usersEndpoints from 'config/api/users/users';
import { logObjects } from 'config/constants/logs';
import ROLES from 'config/constants/ROLES';
import emailValidator from 'config/validators/emailValidator';
import useApiCall from 'hooks/useApiCall';
import useLoadingState from 'hooks/useLoadingState';
import general_messages from 'messages/general_messages';
import table_messages from 'messages/table_messages';
import title_messages from 'messages/title_messages';
import user_messages from 'messages/user_messages';
import validation_messages from 'messages/validation_messages';
import logEvent from 'services/logEvent';

const FORM_ID = 'user_form';

const useStyles = makeStyles(theme => ({
  checkboxWrapper: {
    marginBottom: theme.spacing(3),
  },
  checkboxTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(0.5),
  },
  checkboxDescription: {
    fontSize: '0.95rem',
  },
  switch: {
    '& .MuiFormControlLabel-root': {
      alignItems: 'flex-start',
    },
  },
  buttonDelete: {
    '&.MuiButton-root': {
      padding: '6px 12px',
    },
  },
}));

const CheckboxLabel = ({ field }) => {
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <div className={styles.checkboxWrapper}>
      <Typography className={styles.checkboxTitle}>{t(...user_messages.roles[field])}</Typography>
      <Typography className={styles.checkboxDescription}>{t(...user_messages.roles_description[field])}</Typography>
    </div>
  );
};

CheckboxLabel.propTypes = {
  field: PropTypes.string.isRequired,
};

const UserDialog = ({ onClose, open, initialData, refreshData }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { apiCall } = useApiCall();
  const isEditing = !!initialData;
  const initOrganizationRoles = initialData?.organization_roles ? initialData.organization_roles.map(({ role }) => role) : [];
  const [userOrganization, setUserOrganization] = useState(null);
  const { loading, setLoading, setLoaded } = useLoadingState(false);

  const getOrganizations = async () => {
    const { data } = await apiCall(usersEndpoints.currentUser());
    const { organization } = data;
    setUserOrganization(organization);
  };

  const onSubmitSuccess = () => {
    onClose();
    refreshData();
  };

  const onDelete = async () => {
    const { status } = await apiCall(usersEndpoints.delete(initialData?.id));
    if (status < 400) onSubmitSuccess();
  };

  const onSubmit = async formData => {
    setLoading();
    const {
      profile,
      active_user,
      incident_manager,
      user_administrator,
      security_officer,
      account_owner,
      contract_administrator,
      emergency_button,
      assets_manager,
      asset_task_dashboard_access,
      asset_risk_dashboard_access,
      asset_risk_task_dashboard_access,
      management_dashboard_access,
    } = formData;
    const organization_roles = [
      { role: ROLES.ORGANIZATION.ACTIVE_USER, value: active_user },
      { role: ROLES.ORGANIZATION.INCIDENT_MANAGER, value: incident_manager },
      { role: ROLES.ORGANIZATION.USER_ADMINISTRATOR, value: user_administrator },
      { role: ROLES.ORGANIZATION.SECURITY_OFFICER, value: security_officer },
      { role: ROLES.ORGANIZATION.ACCOUNT_OWNER, value: account_owner },
      { role: ROLES.ORGANIZATION.CONTRACT_ADMINISTRATOR, value: contract_administrator },
      { role: ROLES.ORGANIZATION.ASSETS_MANAGER, value: assets_manager },
      { role: ROLES.ORGANIZATION.EMERGENCY_BUTTON, value: emergency_button },
      { role: ROLES.ORGANIZATION.ASSET_TASK_DASHBOARD_ACCESS, value: asset_task_dashboard_access },
      { role: ROLES.ORGANIZATION.ASSET_RISK_DASHBOARD_ACCESS, value: asset_risk_dashboard_access },
      { role: ROLES.ORGANIZATION.ASSET_RISK_TASK_DASHBOARD_ACCESS, value: asset_risk_task_dashboard_access },
      { role: ROLES.ORGANIZATION.MANAGEMENT_DASHBOARD_ACCESS, value: management_dashboard_access },
    ]
      .filter(({ value }) => value)
      .map(({ role }) => ({ role, organization_id: userOrganization.id }));

    const data = {
      profile,
      organization_roles,
      organization: userOrganization,
    };
    if (isEditing) {
      const { status } = await apiCall(usersEndpoints.update(initialData?.id), { data });
      if (status < 400) onSubmitSuccess();
    } else {
      const response = await apiCall(usersEndpoints.create(initialData?.id), { data }, { showError: false });
      if (response.status < 400) onSubmitSuccess();
      if (response.status >= 400) {
        Object.values(response.data).forEach(error => enqueueSnackbar(error, { variant: 'error' }));
      }
    }
    setLoaded();
  };

  const formik = useFormik({
    initialValues: {
      profile: {
        first_name: initialData?.profile?.first_name || '',
        last_name: initialData?.profile?.last_name || '',
        email: initialData?.email || '',
      },
      active_user: true,
      incident_manager: initOrganizationRoles.includes(ROLES.ORGANIZATION.INCIDENT_MANAGER),
      user_administrator: initOrganizationRoles.includes(ROLES.ORGANIZATION.USER_ADMINISTRATOR),
      security_officer: initOrganizationRoles.includes(ROLES.ORGANIZATION.SECURITY_OFFICER),
      account_owner: initOrganizationRoles.includes(ROLES.ORGANIZATION.ACCOUNT_OWNER),
      contract_administrator: initOrganizationRoles.includes(ROLES.ORGANIZATION.CONTRACT_ADMINISTRATOR),
      assets_manager: initOrganizationRoles.includes(ROLES.ORGANIZATION.ASSETS_MANAGER),
      emergency_button: initOrganizationRoles.includes(ROLES.ORGANIZATION.EMERGENCY_BUTTON),
      asset_task_dashboard_access: initOrganizationRoles.includes(ROLES.ORGANIZATION.ASSET_TASK_DASHBOARD_ACCESS),
      asset_risk_dashboard_access: initOrganizationRoles.includes(ROLES.ORGANIZATION.ASSET_RISK_DASHBOARD_ACCESS),
      asset_risk_task_dashboard_access: initOrganizationRoles.includes(ROLES.ORGANIZATION.ASSET_RISK_TASK_DASHBOARD_ACCESS),
      management_dashboard_access: initOrganizationRoles.includes(ROLES.ORGANIZATION.MANAGEMENT_DASHBOARD_ACCESS),
    },
    onSubmit,
    isInitialValid: false,
    validationSchema: yup.object({
      profile: yup.object().shape({
        first_name: yup.string().required(t(...validation_messages.required)),
        last_name: yup.string().required(t(...validation_messages.required)),
        email: emailValidator({
          required: t(...validation_messages.email_required),
          valid: t(...validation_messages.email_valid),
        }),
      }),
    }),
  });

  useEffect(() => {
    getOrganizations();
    logEvent(logObjects.EDIT_ADD_USER);
  }, []);

  const styles = useStyles();
  return (
    <DialogGrid
      dialogActions={
        <CenteredGrid gridGap={2} width='sm' withoutPadding>
          <ColoredButton customColor='secondary' disabled={!formik.isValid} form={FORM_ID} type='submit' variant='outlined'>
            {isEditing ? t(...general_messages.update) : t(...general_messages.add)}
          </ColoredButton>
          <Button onClick={onClose} size='small'>
            {t(...general_messages.cancel)}
          </Button>
        </CenteredGrid>
      }
      onClose={onClose}
      open={open}
      sending={loading}
      title={isEditing ? t(...title_messages.edit_coworker) : t(...title_messages.add_coworker)}
    >
      <form id={FORM_ID} onSubmit={formik.handleSubmit}>
        <CenteredGrid
          gridGap={1.5}
          rightAdornment={
            isEditing ? (
              <Button data-testid='delete' disabled={loading} onClick={onDelete}>
                {t(...general_messages.delete)}
              </Button>
            ) : undefined
          }
          title={t(...user_messages.active_user)}
          withoutPadding
        >
          <FormTextInput formik={formik} id='profile.first_name' label={t(...general_messages.first_name)} required />
          <FormTextInput formik={formik} id='profile.last_name' label={t(...general_messages.last_name)} required />
          <FormTextInput
            formik={formik}
            id='profile.email'
            label={t(...general_messages.email)}
            readonly={isEditing}
            readonlyInfo={t(...general_messages.email_readonly)}
            required
          />
        </CenteredGrid>
        <CenteredGrid title={t(...table_messages.roles)} withoutPadding>
          <FormSwitch className={styles.switch} disabled formik={formik} id='active_user' label={<CheckboxLabel field='active_user' />} />
          <FormSwitch className={styles.switch} formik={formik} id='incident_manager' label={<CheckboxLabel field='incident_manager' />} />
          <FormSwitch
            className={styles.switch}
            formik={formik}
            id='user_administrator'
            label={<CheckboxLabel field='user_administrator' />}
          />
          <FormSwitch className={styles.switch} formik={formik} id='security_officer' label={<CheckboxLabel field='security_officer' />} />
          <FormSwitch className={styles.switch} formik={formik} id='account_owner' label={<CheckboxLabel field='account_owner' />} />
          <FormSwitch
            className={styles.switch}
            formik={formik}
            id='contract_administrator'
            label={<CheckboxLabel field='contract_administrator' />}
          />
          <FormSwitch className={styles.switch} formik={formik} id='assets_manager' label={<CheckboxLabel field='assets_manager' />} />
          <FormSwitch className={styles.switch} formik={formik} id='emergency_button' label={<CheckboxLabel field='emergency_button' />} />
          <FormSwitch
            className={styles.switch}
            formik={formik}
            id='asset_task_dashboard_access'
            label={<CheckboxLabel field='asset_task_dashboard_access' />}
          />
          <FormSwitch
            className={styles.switch}
            formik={formik}
            id='asset_risk_dashboard_access'
            label={<CheckboxLabel field='asset_risk_dashboard_access' />}
          />
          <FormSwitch
            className={styles.switch}
            formik={formik}
            id='asset_risk_task_dashboard_access'
            label={<CheckboxLabel field='asset_risk_task_dashboard_access' />}
          />
          <FormSwitch
            className={styles.switch}
            formik={formik}
            id='management_dashboard_access'
            label={<CheckboxLabel field='management_dashboard_access' />}
          />
        </CenteredGrid>
      </form>
    </DialogGrid>
  );
};

UserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    organization_roles: PropTypes.array,
    profile: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
  }),
};

UserDialog.defaultProps = {
  initialData: null,
};

export default UserDialog;
