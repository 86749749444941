import React, { useEffect, useRef, useState } from 'react';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import FormPasswordInput from 'components/FormPasswordInput';
import FormTextInput from 'components/FormTextInput';
import SimpleFormLayout from 'components/SimpleFormLayout';
import authEndpoints from 'config/api/auth';
import emailValidator from 'config/validators/emailValidator';
import useApiCall from 'hooks/useApiCall';
import auth_messages from 'messages/auth_messages';
import validation_messages from 'messages/validation_messages';
import { useAuthContext } from 'reactContext/AuthContext';
import StorageService from 'services/StorageService';

const lockStorage = new StorageService('locked_to', { storage: 'localStorage', string: true });

const getLockTime = openTime => {
  const seconds = (openTime - new Date().getTime()) / 1000;
  return seconds.toFixed();
};

const LoginForm = () => {
  const intervalRef = useRef();
  const { t } = useTranslation();
  const [generalError, setGeneralError] = useState(null);
  const [locked, setLocked] = useState(null);
  const [lockedTo, setLockedTo] = useState(null);

  const { apiCall, sending } = useApiCall();
  const { onLogin } = useAuthContext();

  const updateLockedTime = () => {
    const newTime = getLockTime(lockedTo);
    if (newTime > 0) {
      setLocked(newTime);
    } else {
      lockStorage.destroy();
      clearInterval(intervalRef.current);
      setGeneralError(null);
      setLocked(null);
      setLockedTo(false);
    }
  };

  const lock = lockTime => {
    updateLockedTime(lockTime);
    setLockedTo(lockTime);
    setGeneralError(t(...validation_messages.errors.too_many_attempts));
    lockStorage.set(lockTime);
  };

  useEffect(() => {
    if (lockedTo) intervalRef.current = setInterval(updateLockedTime, 100);
    return () => clearInterval(intervalRef.current);
  }, [lockedTo]);

  useEffect(() => {
    const storedTime = lockStorage.get();
    if (storedTime) {
      const storedTimeNumber = +storedTime;
      if (storedTimeNumber > new Date().getTime()) lock(storedTimeNumber);
      else lockStorage.destroy();
    }
  }, []);

  const onSubmit = async values => {
    const data = values;
    data.email = data.email.toLowerCase();
    const { data: responseData, status } = await apiCall(authEndpoints.login(), { data }, { showError: false });
    if (status === 401) {
      setGeneralError(t(...validation_messages.errors.credentials));
      return;
    }
    if (status === 403) {
      const lockTime = new Date(responseData.account_open_time);
      if (lockTime) lock(lockTime.getTime());
      return;
    }
    if (status >= 400) {
      setGeneralError(responseData.detail);
      return;
    }
    onLogin(responseData);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit,
    validationSchema: yup.object({
      email: emailValidator({
        required: t(...validation_messages.email_required),
        valid: t(...validation_messages.email_valid),
      }),
      password: yup.string(t(...validation_messages.password_required)).required(t(...validation_messages.password_required)),
    }),
  });

  const onFormChange = () => {
    if (!lockedTo) setGeneralError(null);
  };

  useEffect(() => {
    // validate on autofill
    formik.validateForm();
  }, []);

  return (
    <SimpleFormLayout
      disabled={!formik.isValid}
      generalError={generalError}
      locked={locked}
      onChange={onFormChange}
      onSubmit={formik.handleSubmit}
      sending={sending}
      submitLabel={t(...auth_messages.navigation.login)}
      title={t(...auth_messages.navigation.sign_in)}
    >
      <FormTextInput formik={formik} id='email' label={t(...auth_messages.email)} required type='email' />
      <FormPasswordInput formik={formik} id='password' label={t(...auth_messages.password)} required />
    </SimpleFormLayout>
  );
};

export default LoginForm;
