import React from 'react';

import { ButtonBase, IconButton, Typography } from '@material-ui/core';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import downloadFromUrl from 'helpers/downloadFromUrl/downloadFromUrl';
import useBoolState from 'hooks/useBoolState';
import general_messages from 'messages/general_messages';
import { FileInDb, FileInForm, isFileInDbGuard } from 'types/FileForm';

import UploadFileDialog from '../_dialogs/UploadFileDialog/UploadFileDialog';
import ContextMenu, { type Element as ContextMenuElement } from '../ContextMenu/ContextMenu';

import useStyles from './FormFilesUploader.styles';

export type FileInUploader = FileInForm | FileInDb;

type Props = {
  id: string;
  removeId?: string;
  formik: FormikProps<any>;
  isPreview?: boolean;
  withLabelField?: false;
};

type UploadedRow = {
  label: string;
  url: string;
  id: number | string;
  isFromDb: boolean;
};

const FormFilesUploader: React.FC<Props> = ({ formik, id, removeId = 'filesToRemove', isPreview, withLabelField }) => {
  const { t } = useTranslation();

  const { state: isDialogOpen, setTrue: openDialog, setFalse: closeDialog } = useBoolState(false);

  const onSubmit = (newFile: FileInForm) => {
    const prevFiles = formik.values[id] || [];
    formik.setFieldValue(id, [...prevFiles, newFile]);
  };

  const onDownloadFactory = (url: string, label: string) => () => downloadFromUrl(url, label);

  const onRemoveFactory = (fileId: number | string, isFromDb: boolean) => () => {
    const prevFiles = formik.values[id] || [];
    const newFiles = prevFiles.filter((file: FileInUploader) => file.id !== fileId);

    formik.setFieldValue(id, newFiles);

    if (isFromDb) {
      const prevFilesToRemove = formik.values[removeId] || [];
      formik.setFieldValue(removeId, [...prevFilesToRemove, fileId]);
    }
  };

  const uploadedFiles: UploadedRow[] = (formik.values[id] || []).map((file: FileInUploader) => {
    const row: UploadedRow = { label: file.name, url: '', id: file.id, isFromDb: file.isFromDb };

    if (isFileInDbGuard(file)) row.url = file.url;
    else {
      row.url = window.URL.createObjectURL(file.file);
      row.label = file.file.name;
    }

    return row;
  });

  const getContextMenuElements = (fileId: number | string, isFromDb: boolean): ContextMenuElement[] => [
    {
      label: t(general_messages.delete),
      onClick: onRemoveFactory(fileId, isFromDb),
    },
  ];

  const styles = useStyles({ isPreview: !!isPreview });

  return (
    <>
      {!isPreview && (
        <ButtonBase className={styles.uploadButton} onClick={openDialog} type='button'>
          <Typography>{t(general_messages.upload_file)}</Typography>
        </ButtonBase>
      )}
      <div>
        {uploadedFiles.map(({ id: fileId, isFromDb, url, label }) => (
          <div key={fileId} className={styles.fileRow}>
            <Typography>{label}</Typography>
            <IconButton onClick={onDownloadFactory(url, label)}>
              <DownloadIcon />
            </IconButton>
            {!isPreview && (
              <ContextMenu elements={getContextMenuElements(fileId, isFromDb)} id={`file-uploader-row-${fileId}`}>
                <IconButton>
                  <MoreIcon />
                </IconButton>
              </ContextMenu>
            )}
          </div>
        ))}
      </div>
      <UploadFileDialog onClose={closeDialog} onSubmit={onSubmit} open={isDialogOpen} withLabelField={!!withLabelField} />
    </>
  );
};

export default FormFilesUploader;
