import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  container: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(3, 4),
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    gridGap: theme.spacing(2),
  },
  icon: {
    // @ts-ignore
    color: theme.palette.secondary[900],
    position: 'relative',

    '&:not(:last-child):before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      width: '2px',
      height: '60%',
      top: '20%',
      right: `-${theme.spacing(0.5) + 1}px`,
      // @ts-ignore
      background: theme.palette.secondary[800],
    },
  },
  buttons: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));
