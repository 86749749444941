import React, { useMemo } from 'react';

import { Container, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useQuery } from 'react-query';

import Jumbotron from 'components/Jumbotron';
import MobileGuttersContainer from 'components/MobileGuttersContainer';
import Section from 'components/Section';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { usersApi } from 'config/api/users/users';
import CONTACT_TYPES from 'config/constants/CONTACT_TYPES';
import EVENTS from 'config/events/pubsub';
import useSubscription from 'hooks/useSubscription';
import title_messages from 'messages/title_messages';
import userDataStorage from 'storages/userDataStorage';

import PersonalInfoSettings from './_components/PersonalInfoSettings';
import SettingsTilesGroup from './_components/SettingsTilesGroup';

const useStyles = makeStyles(theme => ({
  contactsWrapper: {
    display: 'grid',
    gridGap: theme.spacing(2.5),
  },
}));

const FORM = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
  PHONE_NUMBER: 'phone_number',
  TITLE: 'title',
  IMAGE_URL: 'image_url',
};

const MyProfilePage = () => {
  const currentUser = useQuery(QUERY_KEYS.GET_CURRENT_USER, usersApi.getCurrentUser, {
    onSuccess: data => {
      userDataStorage.update(data);
    },
  });

  // TODO use invalidate queries
  useSubscription(EVENTS.USER_PROFILE_UPDATED, currentUser.refetch);

  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  const { user, supervisor, emergency } = useMemo(() => {
    if (!currentUser.data) return {};
    return {
      user: {
        [FORM.FIRST_NAME]: currentUser.data.profile.first_name,
        [FORM.LAST_NAME]: currentUser.data.profile.last_name,
        [FORM.EMAIL]: currentUser.data.profile.email,
        [FORM.PHONE_NUMBER]: currentUser.data.profile.phone_number,
        [FORM.TITLE]: currentUser.data.profile.title,
        [FORM.IMAGE_URL]: currentUser.data.profile.image_url,
      },
      supervisor: {
        [FORM.FIRST_NAME]: currentUser.data.profile.sprvsr_first_name,
        [FORM.LAST_NAME]: currentUser.data.profile.sprvsr_last_name,
        [FORM.EMAIL]: currentUser.data.profile.sprvsr_email,
        [FORM.PHONE_NUMBER]: currentUser.data.profile.sprvsr_phone_number,
        [FORM.TITLE]: currentUser.data.profile.sprvsr_title,
      },
      emergency: {
        [FORM.FIRST_NAME]: currentUser.data.profile.mrgnc_first_name,
        [FORM.LAST_NAME]: currentUser.data.profile.mrgnc_last_name,
        [FORM.EMAIL]: currentUser.data.profile.mrgnc_email,
        [FORM.PHONE_NUMBER]: currentUser.data.profile.mrgnc_phone_number,
        [FORM.TITLE]: currentUser.data.profile.mrgnc_title,
      },
    };
  }, [currentUser]);

  const styles = useStyles();
  return (
    <Container disableGutters={!upMd}>
      <Jumbotron>
        <Typography component='h1' variant='h1'>
          {currentUser.data ? (
            `${currentUser.data.profile.first_name} ${currentUser.data.profile.last_name}`
          ) : (
            <Skeleton height={80} variant='text' width={320} />
          )}
        </Typography>
      </Jumbotron>
      <MobileGuttersContainer>
        <Section title={[...title_messages.personal_info]}>
          <PersonalInfoSettings data={user} FORM={FORM} refreshData={currentUser.refetch} />
        </Section>
        <Section title={[...title_messages.contacts]}>
          <div className={styles.contactsWrapper}>
            <SettingsTilesGroup data={supervisor} FORM={FORM} refreshData={currentUser.refetch} type={CONTACT_TYPES.SUPERVISOR} />
            <SettingsTilesGroup data={emergency} FORM={FORM} refreshData={currentUser.refetch} type={CONTACT_TYPES.EMERGENCY} />
          </div>
        </Section>
      </MobileGuttersContainer>
    </Container>
  );
};

export default MyProfilePage;
