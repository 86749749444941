import { makeStyles, alpha } from '@material-ui/core';

export default makeStyles(theme => ({
  linkCard: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    // @ts-ignore
    border: `1px solid ${theme.palette.secondary[100]}`,
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    textDecoration: 'none',
    transition: theme.transitions.create(['background-color', 'box-shadow'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
      // @ts-ignore
      backgroundColor: alpha(theme.palette.secondary[50], 0.3),
      boxShadow: theme.shadows[1],
    },
  },
  linkLabel: {
    color: theme.palette.text.primary,
  },
  linkName: {
    textDecoration: 'underline',
    // @ts-ignore
    color: theme.palette.secondary[900],
  },
  baseDataSection: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'center',
  },
  baseDataSectionDate: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  paddingTop: {
    paddingTop: theme.spacing(4),
  },
  divider: {
    height: '1px',
    width: '100%',
    backgroundColor: theme.palette.secondary.light,
  },
}));
