import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => {
  const commonTypography = {
    fontWeight: 400,
    color: theme.palette.text.primary,
  };

  return {
    root: {
      display: 'grid',
      gridGap: theme.spacing(5),

      '& h1': {
        ...commonTypography,
        fontWeight: 900,
        fontSize: '2.25em',
        paddingBottom: theme.spacing(2),
      },

      '& h2': {
        ...commonTypography,
        fontWeight: 600,
        fontSize: '1.875em',
      },

      '& h3': {
        ...commonTypography,
        fontWeight: 600,
        fontSize: '1.65em',
        paddingTop: theme.spacing(0.75),
      },

      '& h4': {
        ...commonTypography,
        fontWeight: 500,
        fontSize: '1.5em',
        paddingTop: theme.spacing(0.15),
      },

      '& dl': {
        display: 'flex',
        gap: theme.spacing(5),
        marginBottom: theme.spacing(2),

        '& div': {
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(0.5),

          '& dt': {
            ...commonTypography,
            fontWeight: 600,

            '&::after': {
              content: "':'",
            },
          },

          '& dd': {
            ...commonTypography,
            marginLeft: '0',
          },
        },
      },

      '& table': {
        borderCollapse: 'collapse',
        '& th, & td': {
          padding: theme.spacing(0.5, 2),
          border: `1px solid ${theme.palette.grey[300]}`,
        },

        '& th': {
          textAlign: 'left',
          backgroundColor: theme.palette.divider,
          fontWeight: 500,
        },
        '& tr:nth-child(2n)': {
          backgroundColor: theme.palette.grey[100],
        },
      },
    },

    pt: {
      paddingTop: theme.spacing(2),
    },

    divider: {
      marginTop: theme.spacing(4),
      height: '1px',
      width: '100%',
      backgroundColor: theme.palette.grey[300],
    },

    checkMark: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      '&::before': {
        content: "'✓'",
        fontSize: '1.5em',
        color: theme.palette.primary.main,
      },
    },
  };
});
