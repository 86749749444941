import React, { useEffect, useState } from 'react';

import { Container, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTranslation } from 'react-i18next';

import DashboardInfoBoxResolver from 'components/_paymentStageInfoBoxes/DashboardInfoBoxResolver/DashboardInfoBoxResolver';
import ArticleTilesGrid from 'components/ArticleTilesGrid';
import CarouselWrapper from 'components/CarouselWrapper/CarouselWrapper';
import Jumbotron from 'components/Jumbotron';
import MobileGuttersContainer from 'components/MobileGuttersContainer';
import Section from 'components/Section';
import TextButton from 'components/TextButton/TextButton';
import UserKpiSection from 'components/UserKpiSection/UserKpiSection';
import articleEndpoints from 'config/api/article/article';
import { parseContentItemForFE } from 'config/api/selfTests/parsers';
import CONTENT_TYPES from 'config/constants/CONTENT_TYPES';
import useApiCall from 'hooks/useApiCall';
import assets_messages from 'messages/assets_messages';
import title_messages from 'messages/title_messages';
import { useHelpDialogContext } from 'reactContext/HelpDialogContext/HelpDialogContext';
import userDataStorage from 'storages/userDataStorage';

import ActivitiesTable from './_components/ActivitiesTable';
import AssetTaskList from './_components/AssetTaskList/AssetTaskList';
import RecommendedSection from './_components/RecommendedSection/RecommendedSection';
import UpcomingMeetingsDashboardFeed from './_components/UpcomingMeetingsDashboardFeed/UpcomingMeetingsDashboardFeed';

const DashboardPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const { apiCall } = useApiCall();
  const { showHelpDialog } = useHelpDialogContext();

  const [articlesData, setArticlesData] = useState(null);
  const getArticlesData = async () => {
    const { data } = await apiCall(articleEndpoints.getSaved());
    const parsedData = parseContentItemForFE(data);
    setArticlesData(parsedData);
  };

  const showYearlyAssetActivitiesHelpDialog = () => {
    showHelpDialog({
      title: t(assets_messages.asset_tasks_introduction.title),
      body: t(assets_messages.asset_tasks_introduction.body),
    });
  };

  const userData = userDataStorage.get() || {};

  useEffect(() => {
    getArticlesData();
  }, []);

  return (
    <Container disableGutters={!upMd}>
      <Jumbotron>
        <Typography component='h1' variant='h1'>
          {userData ? `${userData.first_name} ${userData.last_name}` : <Skeleton height={80} variant='text' width={320} />}
        </Typography>
      </Jumbotron>
      <DashboardInfoBoxResolver />
      <MobileGuttersContainer>
        <Section
          showSeparator
          smallPadding
          title={title_messages.asset_tasks_table}
          titleLineAddon={[
            <TextButton key='introduction' onClick={showYearlyAssetActivitiesHelpDialog}>
              {t(assets_messages.asset_tasks_introduction.button)}
            </TextButton>,
          ]}
        >
          <AssetTaskList />
        </Section>
      </MobileGuttersContainer>
      <MobileGuttersContainer>
        <UpcomingMeetingsDashboardFeed />
      </MobileGuttersContainer>
      <Section showSeparator smallPadding title={title_messages.activity} titleWrapper={MobileGuttersContainer}>
        <CarouselWrapper noTopPadding>
          <UserKpiSection />
        </CarouselWrapper>
        <MobileGuttersContainer>
          <ActivitiesTable />
        </MobileGuttersContainer>
      </Section>
      <MobileGuttersContainer>
        <Section noMargin title={title_messages.recommended_content}>
          <RecommendedSection />
        </Section>
      </MobileGuttersContainer>
      <MobileGuttersContainer>
        <Section title={title_messages.saved_articles}>
          <ArticleTilesGrid data={articlesData} type={CONTENT_TYPES.ARTICLE} />
        </Section>
      </MobileGuttersContainer>
    </Container>
  );
};

export default DashboardPage;
