import React from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import MobileGuttersContainer from 'components/MobileGuttersContainer';

const useStyles = makeStyles(theme => ({
  section: {
    display: 'grid',
    gridGap: theme.spacing(1),
    paddingTop: ({ paddingTop }) => theme.spacing(paddingTop),
  },
  titleLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // eslint-disable-next-line no-nested-ternary
    paddingBottom: ({ smallPadding, noMargin }) => (noMargin ? '0' : smallPadding ? theme.spacing(2) : theme.spacing(5)),
    borderBottom: ({ showSeparator }) => (showSeparator ? `3px solid ${theme.palette.primary[100]}` : null),
    [theme.breakpoints.down('xs')]: {
      gap: theme.spacing(1),
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
      paddingBottom: ({ smallPadding }) => (smallPadding ? theme.spacing(1) : theme.spacing(3)),
    },
  },
  tileAddon: {
    display: 'flex',
    gap: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'flex-end',
    },
  },
}));

const Section = ({
  title,
  children,
  titleLineAddon,
  gutters,
  smallPadding,
  smallTitle,
  paddingTop,
  showSeparator,
  noMargin,
  titleWrapper,
}) => {
  const { t } = useTranslation();
  const styles = useStyles({ smallPadding, paddingTop, showSeparator, noMargin });

  const TitleWrapper = titleWrapper || React.Fragment;

  const TitleLine = () => (
    <div className={styles.titleLine}>
      <Typography component='h2' variant={smallTitle ? 'h3' : 'h2'}>
        {Array.isArray(title) ? t(...title) : title}
      </Typography>
      <div className={styles.tileAddon}>{titleLineAddon && titleLineAddon}</div>
    </div>
  );

  return (
    <section className={styles.section}>
      <TitleWrapper>
        {gutters ? (
          <MobileGuttersContainer>
            <TitleLine />
          </MobileGuttersContainer>
        ) : (
          <TitleLine />
        )}
      </TitleWrapper>
      {children}
    </section>
  );
};

Section.propTypes = {
  title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]).isRequired,
  children: PropTypes.node,
  titleWrapper: PropTypes.node,
  titleLineAddon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  gutters: PropTypes.bool,
  smallPadding: PropTypes.bool,
  smallTitle: PropTypes.bool,
  showSeparator: PropTypes.bool,
  paddingTop: PropTypes.number,
  noMargin: PropTypes.bool,
};

Section.defaultProps = {
  titleLineAddon: null,
  gutters: false,
  smallPadding: false,
  smallTitle: false,
  showSeparator: false,
  paddingTop: 8,
  noMargin: false,
};

export default Section;
