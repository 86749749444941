import React, { useMemo, useState } from 'react';

import { Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import AssetTaskDialog from 'components/_dialogs/AssetTaskDialog/AssetTaskDialog';
import FooterButton from 'components/FooterButton';
import Table from 'components/Table';
import { legacyActiveUserAssetTasksApi } from 'config/api/activeUserAssetTasks/activeUserAssetTasks';
import { parseAllActiveUserAssetTasksForFE } from 'config/api/activeUserAssetTasks/activeUserAssetTasks.parsers';
import { ActiveUserAssetTaskInList } from 'config/api/activeUserAssetTasks/activeUserAssetTasks.types';
import DATE_FORMAT from 'config/constants/DATE_FORMAT';
import EVENTS from 'config/events/pubsub';
import usePagination from 'hooks/usePagination';
import usePrepareAvailableFilters from 'hooks/usePrepareAvailableFilters/usePrepareAvailableFilters';
import useSubscription from 'hooks/useSubscription';
import assets_messages from 'messages/assets_messages';
import general_messages from 'messages/general_messages';
import userAssetsWithTasksFilterFactory from 'services/_filters/parsers/_assetsFilters/userAssetsWithTasksFilter';
import assetTaskStatusFilter from 'services/_filters/parsers/_assetTasksFilters/assetTaskStatusFilter';
import parseFiltersForRequest from 'services/parseFiltersForRequest';
import userDataStorage from 'storages/userDataStorage';
import { TableDataMappingRow } from 'types/Table';

import useStyles from './AssetTaskList.styles';

const AssetTaskList: React.FC = () => {
  // @ts-ignore
  const { id: userId } = userDataStorage.get() || { id: null };
  const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null);
  const isTaskDetailsDialogOpen = selectedTaskId !== null;
  const closeTaskDetailsDialog = () => setSelectedTaskId(null);

  const availableFilters = usePrepareAvailableFilters({
    status: assetTaskStatusFilter,
    asset: userAssetsWithTasksFilterFactory({ asset_owner: userId }),
  });

  const { t } = useTranslation();
  const [sort, setSort] = useState<{ key: string | null; direction: string | null }>({ key: null, direction: null });
  const onSort = (key: string, direction: string) => setSort({ key, direction });
  const [filters, setFilters] = useState({});

  const {
    data: tableData,
    getData: getTableData,
    hasNextPage,
    loading,
    refreshData,
  } = usePagination({
    endpointFunc: legacyActiveUserAssetTasksApi.getAllActiveUserAssetTasks,
    limits: [5, 5, 5],
    isGet: true,
    dependencies: [sort, filters],
    sort,
    filters: parseFiltersForRequest(filters, {}, availableFilters || {}),
  });

  useSubscription(EVENTS.ASSET_TASK_UPDATED, refreshData);

  const dataMapping: TableDataMappingRow<ActiveUserAssetTaskInList>[] = useMemo(
    () => [
      {
        label: t(assets_messages.asset_tasks_table.task_name),
        get: data => data.name,
        id: 'name',
        width: '50%',
      },
      {
        label: t(assets_messages.asset_tasks_table.due_date),
        get: data => format(data.dueDate, DATE_FORMAT.defaultDate),
        id: 'due_date',
        width: '25%',
      },
      {
        label: t(assets_messages.asset_tasks_table.status),
        get: data => t(assets_messages.asset_task_status[data.status]),
        id: 'status',
        width: '25%',
      },
    ],
    [],
  );

  const styles = useStyles();

  if (!tableData && !loading) {
    return (
      <Typography align='center' className={styles.emptyFeedback}>
        {t(assets_messages.asset_tasks_table.empty_list)}
      </Typography>
    );
  }

  return (
    <>
      <Table
        clickableRow
        data={tableData && parseAllActiveUserAssetTasksForFE(tableData)}
        dataMapping={dataMapping}
        filtersService={{ filters, setFilters, availableFilters }}
        onRowClick={(_, element) => setSelectedTaskId(element.id)}
        onSort={onSort}
        refetching={loading}
        sort={sort}
      />
      {hasNextPage && (
        // @ts-ignore
        <FooterButton loading={loading} onClick={getTableData}>
          {t(general_messages.show_more)}
        </FooterButton>
      )}
      <AssetTaskDialog forActiveUser onClose={closeTaskDetailsDialog} open={isTaskDetailsDialogOpen} taskId={selectedTaskId} />
    </>
  );
};

export default AssetTaskList;
