const user_messages = {
  new_user: ['user>>new_user', 'New user'],
  add_from_file: ['user>>add_from_file', 'Add from file'],
  file_example: ['user>>file_example', 'Example of file'],
  file_example_content: ['user>>file_example_content', 'john.andersson@bolaget.se;John; Andersson Kim.Jong@bolaget.se;Kim;Jong'],
  file_formatting: ['user>>file_formatting', 'File formatting'],
  organization: ['user>>organization', 'Organization'],
  incorrect_file: ['user>>incorrect_file', 'Provided file is incorrect'],
  active_user: ['user>>active_user', 'Active user'],
  file_formatting_instruction: [
    'user>>file_formatting_instruction',
    `If you want to add many users for your organization at once, you can import a file. The file must be in text format and contain three columns of data per row separated by a semicolon (;). First column is email, second is first name and the last column is Last name.`,
  ],
  roles: {
    account_owner: ['user>>roles>>account_owner', 'Account owner'],
    incident_manager: ['user>>roles>>incident_manager', 'Incident manager'],
    user_administrator: ['user>>roles>>user_administrator', 'User administrator'],
    security_officer: ['user>>roles>>security_officer', 'Security officer'],
    contract_administrator: ['user>>roles>>contract_administrator', 'Contract administrator'],
    active_user: ['user>>roles>>active_user', 'Active user'],
    assets_manager: ['user>>roles>>assets_manager', 'Assets manager'],
    emergency_button: ['user>>roles>>emergency_button', 'Emergency button'],
    asset_task_dashboard_access: ['user>>roles>>asset_task_dashboard_access', 'Asset task dashboard access'],
    asset_risk_dashboard_access: ['user>>roles>>asset_risk_dashboard_access', 'Asset risk dashboard access'],
    asset_risk_task_dashboard_access: ['user>>roles>>asset_risk_task_dashboard_access', 'Asset risk task dashboard access'],
    management_dashboard_access: ['user>>roles>>management_dashboard_access', 'Management dashboard access'],
  },
  roles_description: {
    active_user: [
      'user>>roles_description>>active_user',
      'This is the standard role for all users. The user read articels, use guides and create incidents',
    ],
    incident_manager: [
      'user>>roles_description>>incident_manager',
      'This is a person within your organization that can manage incident reports.',
    ],
    user_administrator: ['user>>roles_description>>user_administrator', 'This is a person within your organization that can manage users.'],
    security_officer: [
      'user>>roles_description>>security_officer',
      'This is a person within your organization that can work book advise meetings with PocketSafe',
    ],
    account_owner: [
      'user>>roles_description>>account_owner',
      'Owns the account for an organization, the one that can terminate the account',
    ],
    contract_administrator: [
      'user>>roles_description>>contract_administrator',
      'A user within a customer organization that can inquire for offers of services and handle the offers',
    ],
    assets_manager: ['user>>roles_description>>assets_manager', 'A user within a assets manager that can manage an assets and tasks'],
    emergency_button: ['user>>roles_description>>emergency_button', 'User can uses Emergency button'],
    asset_task_dashboard_access: ['user>>roles_description>>asset_task_dashboard_access', 'User can access asset Task dashboard'],
    asset_risk_dashboard_access: ['user>>roles_description>>asset_risk_dashboard_access', 'User can access  asset Risk dashboard'],
    asset_risk_task_dashboard_access: [
      'user>>roles_description>>asset_risk_task_dashboard_access',
      'User can access asset Risk Task dashboard',
    ],
    management_dashboard_access: ['user>>roles_description>>management_dashboard_access', 'User can access Management dashboard'],
  },
  abort_uploading_confirm_title: ['user>>abort_uploading_confirm_title', 'Cancel upload'],
  abort_uploading_confirm: ['user>>abort_uploading_confirm', 'Are you sure you want to abort users upload?'],
  upload_summary: [
    'user>>upload_summary',
    `You've successfully created {{created}} users, {{skipped}} was skipped, and {{invalid}} users was invalid`,
  ],
  user_kpi: {
    reportedIncidents: ['user>>user_kpi>>reportedIncidents', 'Reported incidents'],
    completedGuides: ['user>>user_kpi>>completedGuides', 'Completed guides'],
    notCompletedGuides: ['user>>user_kpi>>notCompletedGuides', 'Not completed guides'],
    completedSelfTests: ['user>>user_kpi>>completedSelfTests', 'Completed self tests'],
    notCompletedSelfTests: ['user>>user_kpi>>notCompletedSelfTests', 'Not completed self tests'],
  },
};

export default user_messages;
