import activeUserAssetTasksFilterDataApi from 'config/api/activeUserAssetTasksFilterData/activeUserAssetTasksFilterData';
import { ActiveUserAssetTasksFilterDataParams } from 'config/api/activeUserAssetTasksFilterData/activeUserAssetTasksFilterData.types';
import { AssetInformationFilters } from 'config/api/assetInformationChoices/assetInformationChoices.types';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import assets_messages from 'messages/assets_messages';
import { DynamicTableFilter } from 'types/TableFilter';

const userAssetsWithTasksFilterFactory = (params: ActiveUserAssetTasksFilterDataParams): DynamicTableFilter<AssetInformationFilters> => ({
  isDynamic: true,
  queryFn: () => activeUserAssetTasksFilterDataApi.getActiveUserAssetTasksFilterData(params),
  sectionName: assets_messages.asset_tasks_table.asset_filter_name,
  isSingleSelect: false,
  queryKey: QUERY_KEYS.GET_ALL_ASSET_TASKS_BY_OWNER,
});

export default userAssetsWithTasksFilterFactory;
