import axios from 'axios';

import createUrlWithParams from 'helpers/createUrlWithParams';

import { parseActiveUserAssetTasksFilterDataToTableFilters } from './activeUserAssetTasksFilterData.parsers';
import type ActiveUserAssetTasksFilterDataApi from './activeUserAssetTasksFilterData.types';

const endpoint = 'api/v1/active_user/asset_tasks/filter_data/';

const activeUserAssetTasksFilterDataApi: ActiveUserAssetTasksFilterDataApi = {
  getActiveUserAssetTasksFilterData: async params => {
    const { data } = await axios.get(createUrlWithParams(endpoint, params));
    return parseActiveUserAssetTasksFilterDataToTableFilters(data);
  },
};

export default activeUserAssetTasksFilterDataApi;
