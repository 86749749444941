import React, { useMemo, useState } from 'react';

import { Button, Collapse, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LanguageIcon from '@material-ui/icons/Language';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { ReactComponent as AlertIcon } from 'assets/icons/alert.svg';
import MenuSection from 'components/Layout/_components/MenuSection';
import { usersApi } from 'config/api/users/users';
import checkProtectedFeatureAccess from 'config/permissions/checkProtectedFeatureAccess';
import ProtectedFeatures from 'config/permissions/ProtectedFeatures';
import getShortLanguage from 'helpers/getShortLanguage';
import useOrganizationPaymentStageInfo from 'hooks/useOrganizationPaymentStageInfo/useOrganizationPaymentStageInfo';
import general_messages from 'messages/general_messages';
import menu_messages from 'messages/menu_messages';
import { useAuthContext } from 'reactContext/AuthContext';
import { COMMON_DIALOG_TYPES, useCommonDialogsContext } from 'reactContext/CommonDialogsContext';
import useLayoutStore from 'storages/layoutStore';
import userDataStorage from 'storages/userDataStorage';

const drawerWidth = 320;
const closedDrawerWidth = 80;

const useStyles = makeStyles(theme => ({
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  wrapper: {
    borderTop: `2px solid ${theme.palette.common.black}`,
    flexGrow: 0,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'stretch',
    position: 'sticky',
    bottom: '0',
    background: theme.palette.background.dark,
    width: ({ isMenuOpen }) => (isMenuOpen ? drawerWidth : closedDrawerWidth),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      width: () => '100%',
    },
  },
  list: {
    width: '100%',
  },
  icon: {
    color: theme.palette.text.inverted,
    minWidth: '40px',
    paddingLeft: ({ isMenuOpen }) => (isMenuOpen ? 'unset' : theme.spacing(0.75)),
    transition: theme.transitions.create('padding-left', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  text: {
    animationName: '$fadeIn',
    animationDuration: 300,
    '& span': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.125rem !important',
      },
    },
  },
  errorText: {
    animationName: '$fadeIn',
    animationDuration: 800,
  },
  languageText: {
    paddingLeft: ({ isMenuOpen }) => (isMenuOpen ? theme.spacing(2) : theme.spacing(2.8)),
  },
  item: {
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.secondary.light,
        '& $icon': {
          color: theme.palette.secondary.light,
        },
      },
    },
  },
  logout: {
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.error.light,
        '& $icon': {
          color: theme.palette.error.light,
        },
      },
    },
  },
  branch: {
    position: 'absolute',
    bottom: 0,
    opacity: '.75',
    whiteSpace: 'normal',
    fontSize: '11px',
  },
  errorButton: {
    padding: theme.spacing(1.5, 0.5),
    width: '100%',
    color: theme.palette.text.inverted,
    background: theme.palette.error.main,
    '&:hover': {
      background: theme.palette.error.dark,
    },
    minWidth: '38px',
  },
}));

const FooterSection = () => {
  const { isMenuOpen, closeMenu } = useLayoutStore();

  const { onLogout, isAuthorized } = useAuthContext();
  const { openCommonDialog } = useCommonDialogsContext();
  const { i18n, t } = useTranslation();
  const userData = userDataStorage.get() || {};

  const { stage } = useOrganizationPaymentStageInfo();

  const [isLangOpen, setLangOpen] = useState(false);
  const toggleLang = () => setLangOpen(prevState => !prevState);
  const langMutation = useMutation('Set language for user', usersApi.setLanguage);

  const changeLanguage = code => async () => {
    await i18n.changeLanguage(code);
    if (isAuthorized) langMutation.mutate({ language_code: code });
    setLangOpen(false);
  };

  const onLogoutClick = () => {
    onLogout();
    closeMenu();
  };

  const openIncidentDialog = () => {
    openCommonDialog(COMMON_DIALOG_TYPES.INCIDENT_GUIDE);
    // closeMenu();
  };

  const openEmergencyDialog = () => {
    openCommonDialog(COMMON_DIALOG_TYPES.EMERGENCY);
  };

  const languages = useMemo(
    () =>
      [
        { label: t(...general_messages.languages.en), langCode: 'en' },
        { label: t(...general_messages.languages.sv), langCode: 'sv' },
      ].filter(({ langCode }) => langCode !== i18n.language),
    [i18n.language],
  );

  const styles = useStyles({ isMenuOpen });
  return (
    <MenuSection className={styles.wrapper} collapsible={false} divider={false}>
      <List className={styles.list}>
        {isAuthorized &&
          checkProtectedFeatureAccess(ProtectedFeatures.EmergencyButton, { organization: userData.roles, paymentStage: [stage] })
            .hasAccess && (
            <ListItem>
              <Button className={styles.errorButton} onClick={openEmergencyDialog} variant='contained'>
                {isMenuOpen ? (
                  <span className={clsx(styles.text, styles.errorText)}>{t(menu_messages.emergency_button)}</span>
                ) : (
                  <NotificationsActiveIcon />
                )}
              </Button>
            </ListItem>
          )}
        {isAuthorized && checkProtectedFeatureAccess(ProtectedFeatures.MyOrganizationSections, { paymentStage: [stage] }).hasAccess && (
          <ListItem>
            <Button className={styles.errorButton} onClick={openIncidentDialog} variant='contained'>
              {isMenuOpen ? <span className={clsx(styles.text, styles.errorText)}>{t(menu_messages.report_incident)}</span> : <AlertIcon />}
            </Button>
          </ListItem>
        )}
        <ListItem button className={styles.item} onClick={toggleLang}>
          <ListItemIcon className={styles.icon}>
            <LanguageIcon />
          </ListItemIcon>
          {isMenuOpen && <ListItemText className={styles.text} primary={t(`languages>>${getShortLanguage(i18n.language)}`, 'Language')} />}
        </ListItem>
        <Collapse in={isLangOpen} timeout='auto' unmountOnExit>
          <List>
            {languages.map(({ label, langCode }) => (
              <ListItem key={langCode} button className={clsx(styles.item, styles.languageText)} onClick={changeLanguage(langCode)}>
                <ListItemText className={styles.text} primary={isMenuOpen ? label : langCode.toUpperCase()} />
              </ListItem>
            ))}
          </List>
        </Collapse>
        {isAuthorized && (
          <ListItem button className={styles.logout} data-cy='logout_button' onClick={onLogoutClick}>
            <ListItemIcon className={styles.icon}>
              <ExitToAppIcon />
            </ListItemIcon>
            {isMenuOpen && <ListItemText className={styles.text} primary={t(...menu_messages.logout)} />}
          </ListItem>
        )}
      </List>
      {isMenuOpen && <div className={styles.branch}>{process.env.REACT_APP_BRANCH}</div>}
    </MenuSection>
  );
};

export default FooterSection;
