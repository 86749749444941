import React from 'react';

import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ensureIsArray from 'helpers/ensureIsArray';

import safeT from '../../helpers/safeT/safeT';

const FormSelect = ({ className, formik, id, label, disabled, options, variant, color, required, size }) => {
  const { t } = useTranslation();
  const { touched, errors, handleChange, values } = formik;
  const value = get(values, id);
  const error = get(errors, id);
  const isTouched = get(touched, id);

  const setTouched = () => {
    formik.setFieldTouched(id, true);
  };

  const unifiedLabel = required ? `${label} * ` : label;

  return (
    <FormControl className={className} error={isTouched && Boolean(error)} size={size} variant={variant}>
      <InputLabel id={`${id}-label`}>{unifiedLabel}</InputLabel>
      <Select
        color={color}
        data-testid={`${id}-select`}
        disabled={disabled || !options.length}
        error={isTouched && Boolean(error)}
        id={id}
        label={unifiedLabel}
        labelId={`${id}-label`}
        onBlur={setTouched}
        onChange={handleChange(id)}
        value={value}
      >
        {options.map(({ key, label: optionLabel }) => {
          const translationArray = ensureIsArray(optionLabel);
          return (
            <MenuItem key={key} value={key}>
              {safeT(t, ...translationArray, translationArray[1])}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText error>{isTouched && error}</FormHelperText>
    </FormControl>
  );
};

FormSelect.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  color: PropTypes.string,
  formik: PropTypes.shape({
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    setFieldTouched: PropTypes.func,
    values: PropTypes.shape({}),
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  required: PropTypes.bool,
};

FormSelect.defaultProps = {
  variant: 'outlined',
  disabled: false,
  className: null,
  options: [],
  color: 'primary',
  required: false,
  size: 'medium',
};

export default FormSelect;
