import axios from 'axios';

import createUrlWithParams from 'helpers/createUrlWithParams';

import METHOD from '../_methods';

import { parseSingleAssetForFE, parseAllAssetForFE, parseCreateInputForBE, parseUpdateInputForBE } from './assets.parsers';
import type AssetsApi from './assets.types';

const endpoint = 'api/v1/assets/';

// TODO: remove if react-query
const assets: Record<string, (params: any) => { url: string; method: string }> = {
  getAllAssets: params => ({ url: createUrlWithParams(`${endpoint}search`, params), method: METHOD.POST }),
};

export const assetsApi: AssetsApi = {
  getAllAssets: async () => {
    const { data } = await axios.get(endpoint);
    return parseAllAssetForFE(data);
  },
  getSingleAsset: async id => {
    const { data } = await axios.get(`${endpoint}${id}/`);
    return parseSingleAssetForFE(data);
  },
  removeAsset: async id => axios.delete(`${endpoint}${id}/`),
  createAsset: async data => axios.post(endpoint, parseCreateInputForBE(data)),
  updateAsset: async ({ id, data }) => axios.put(`${endpoint}${id}/`, parseUpdateInputForBE(data)),
};

export default assets;
