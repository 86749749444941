import React, { useEffect } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Switch, useLocation } from 'react-router-dom';

import Layout from 'components/Layout';
import logEndpoints from 'config/api/log';
import { events } from 'config/constants/logs';
import locale from 'config/locale';
import DashboardPage from 'pages/DashboardPage';
import { useAuthContext } from 'reactContext/AuthContext';
import CommonDialogsProvider from 'reactContext/CommonDialogsContext';
import ConfirmationModalProvider from 'reactContext/ConfirmationModalContext';
import PrivateRoute from 'router/_components/PrivateRoute';
import PublicRoute from 'router/_components/PublicRoute';
import PATHS from 'router/PATHS';
import routes from 'router/routes';
import resolveLogData from 'services/resolveLogData';

import HelpDialogProvider from '../../reactContext/HelpDialogContext/HelpDialogContext';

const Router = () => {
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const { isAuthorized, updateUserData } = useAuthContext();

  const logEvent = (event = events.CLOSED) => {
    const data = resolveLogData(pathname, event);
    if (data) axios(logEndpoints.fullLogEndpoint, { method: 'POST', data, headers: { 'Content-Type': 'application/json' } });
  };

  useEffect(() => {
    logEvent(events.OPENED);
  }, [pathname]);

  useEffect(() => {
    window.onbeforeunload = logEvent;
    if (isAuthorized) updateUserData();
  }, []);

  useEffect(() => {
    // normalize language
    if (i18n.language.length > 2) i18n.changeLanguage(i18n.language.slice(0, 2));
  }, [i18n.language]);

  return (
    <MuiPickersUtilsProvider locale={locale[i18n.language || 'en']} utils={DateFnsUtils}>
      <CommonDialogsProvider>
        <Layout>
          <HelpDialogProvider>
            <ConfirmationModalProvider>
              <Switch>
                {routes.map(({ isPrivate, ...rest }) => {
                  if (isPrivate) {
                    return <PrivateRoute key={rest.path} exact {...rest} />;
                  }
                  return <PublicRoute key={rest.path} exact {...rest} />;
                })}
                <PrivateRoute component={DashboardPage} path={PATHS.ROOT} />
              </Switch>
            </ConfirmationModalProvider>
          </HelpDialogProvider>
        </Layout>
      </CommonDialogsProvider>
    </MuiPickersUtilsProvider>
  );
};

export default Router;
